import React from 'react'
import { Content } from '~/common/Content/Content.styled'
import { ContentLayout } from '~/common/ContentLayout/ContentLayout'
import { ArticlesPagePath } from '~/pages/articles'
import { ArticleData } from '~/types/article'
import { Box } from '~/ui/core/Box/Box'
import { Button } from '~/ui/core/Button/Button'
import { Flex } from '~/ui/core/Flex/Flex'
import { Text } from '~/ui/core/Text/Text'
import * as Styled from './Article.styled'

type ArticleProps = {
  articleData: ArticleData
}

export const Article = ({
  articleData: {
    data: { markdownRemark: post },
  },
}: ArticleProps) => {
  const { frontmatter, html, timeToRead } = post
  const { date, title } = frontmatter

  return (
    <ContentLayout>
      <article
        itemScope
        itemType="http://schema.org/Article"
      >
        <Styled.Header css={{ marginBottom: '$3xl' }}>
          <Text
            as="h2"
            align="center"
            color="pink"
            size="2xl"
          >
            <span itemProp="headline">{title}</span>
          </Text>
          <Box
            margin={['3xs', '0', '0']}
            css={{ opacity: 0.5 }}
          >
            <Text
              as="aside"
              align="center"
              size="sm"
            >
              <time>{date}</time> &middot; {timeToRead} minute{timeToRead > 1 ? 's' : ''} read
            </Text>
          </Box>
        </Styled.Header>
        <Content
          dangerouslySetInnerHTML={{ __html: html }}
          itemProp="articleBody"
        />
      </article>
      <Flex
        alignItems="center"
        direction="column"
        gap="xl"
        css={{
          marginTop: '$4xl',
          '@lg': {
            marginTop: '$5xl',
          },
        }}
      >
        <Text
          align="center"
          size="lg"
          weight="bold"
        >
          Thanks for reading!
          <br />
          🙏
        </Text>
        <Button
          accent="cyan"
          to={ArticlesPagePath}
          asGatsbyLink
        >
          Read more articles
        </Button>
      </Flex>
    </ContentLayout>
  )
}
