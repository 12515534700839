import React, { PropsWithChildren } from 'react'
import { Box } from '~/ui/core/Box/Box'
import { Container } from '../Container/Container'

export const ContentLayout = ({ children }: PropsWithChildren) => {
  return (
    <Box
      backgroundColor="blackDark"
      padding={['3xl', 'base', '4xl']}
      css={{
        padding: '$3xl $base $4xl',
        '@lg': {
          paddingBottom: '$5xl',
          paddingTop: '$4xl',
        },
      }}
    >
      <Container>{children}</Container>
    </Box>
  )
}
