import { graphql, Link } from 'gatsby'
import * as React from 'react'
import { Layout } from '~/common/Layout/Layout'
import { SEO } from '~/common/Seo/Seo'
import { Article } from '~/features/ArticleTemplate/Article/Article'
import { ArticleData } from '~/types/article'

const ArticleTemplate = (articleData: ArticleData) => (
  <Layout>
    <Article articleData={articleData} />
  </Layout>
)

export default ArticleTemplate

export const Head = ({ data: { markdownRemark: post } }: ArticleData) => (
  <SEO
    title={post.frontmatter.title}
    description={post.frontmatter.description || post.excerpt}
  />
)

export const pageQuery = graphql`
  query ArticleBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
      timeToRead
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
