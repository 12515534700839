import { styled } from '~/ui/theme'

export const Content = styled('div', {
  fontSize: '$base',
  lineHeight: '$base',

  'p + p': {
    marginTop: '1.5rem',
  },

  'h2, h3, h4': {
    marginBottom: '$sm',
    marginTop: '$xl',
  },

  h2: {
    fontSize: '$xl',
    lineHeight: '$xl',
  },

  h3: {
    fontSize: '$lg',
    lineHeight: '$lg',
  },

  h4: {
    fontSize: '$md',
    lineHeight: '$md',
  },

  a: {
    color: '$cyan',

    '&:hover': {
      textDecoration: 'none',
    },
  },

  hr: {
    border: '1px solid var(--background)',
    my: '$2xl',
  },

  ul: {
    listStyleType: 'square',
    paddingLeft: '1.4rem',
    my: '$lg',
  },

  '.gatsby-resp-image-wrapper, .gatsby-highlight': {
    mx: '-$base !important',

    '@lg': {
      mx: '-$3xl !important',
    },
  },

  blockquote: {
    my: '$2xl',
    mx: '0',
    py: '$base',
    paddingLeft: '$xl',
    position: 'relative',

    '&::before': {
      content: '',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      backgroundColor: '$purple',
      width: '0.5rem',
      radius: '$xs',
    },
  },
})
